.login {
    
    .columns {
        height: 100vh;

        .column {
            
            &.is-one-quarter {
                background: linear-gradient(54deg, #00235f 34%, #0A1D3D);
            }

        }

    }

}